<template>
  <v-card flat>
    <v-container
      v-show="!showAnsprechpartner"
      grid-list-xl
    >
      <v-row>
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="4"
          class="d-flex align-stretch"
        >
          <v-card
            width="100%"
            :raised="currentLocationId === item.uri"
            :shaped="currentLocationId === item.uri"
            :ripple="false"
            @click="openAnsprechpartner({ id: item.uri, title: item.location })"
          >
            <!-- <swiper
              ref="swiperTwoRow"
              :options="swiperOptions"
              style="height: 4em;"
            >
              <swiper-slide
                v-for="(marke, m) in item.marken"
                :key="m"
              >
                <v-card
                  flat
                  class="my-1"
                >
                  <v-img
                    width="3.75em"
                    aspect-ratio="1"
                    :src="`https://dresen.de/wp-content/uploads/2022/01/${marke}-logo_1500x1000.png` | mImage"
                    contain
                    class="mx-auto"
                  />
                </v-card>
              </swiper-slide>
              <swiper-slide
                v-if="item.specialMarke"
              >
                <v-card
                  flat
                  class="my-auto"
                >
                  <v-img
                    width="3.75em"
                    aspect-ratio="1"
                    :src="item.specialMarke | mImage"
                    contain
                    class="mx-auto"
                  />
                </v-card>
              </swiper-slide>
            </swiper> -->
            <v-img
              :src="item.image | mImage"
            >
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate />
                </v-row>
              </template>
            </v-img>
            <v-card-title class="grey--text font-weight-bold px-2" style="word-break: break-word; height: 4em; font-size: 1.3em;">
              {{ item.location }}
            </v-card-title>
            <v-card-text class="flex-column align-start pa-2 body-2 black--text">
              <div class="font-weight-bold body-1">
                {{ item.title }}
              </div>
              <div class="body-1 pb-1" v-text="item.address" />
              <div v-if="item.phone">
                <v-icon left>
                  mdi-phone
                </v-icon>
                {{ item.phone }}
              </div>
              <div v-if="item.fax">
                <v-icon left>
                  mdi-fax
                </v-icon>
                {{ item.fax }}
              </div>
              <div v-if="item.mail">
                <v-icon left>
                  mdi-email
                </v-icon>
                {{ item.mail }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <m-ansprechpartner v-if="showAnsprechpartner" :location-id="showAnsprechpartner" />
  </v-card>
</template>

<script>
import { db } from '../../../plugins/firebase'
import $storage from '../../../plugins/local_storage'
import MAnsprechpartner from '../MAnsprechpartner/MAnsprechpartner'
export default {
  name: 'MStandorte',
  components: {
    MAnsprechpartner
  },
  props: {
    currentLocationId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    scrapeData: $storage.getJSON('m-standorte:scrape_data').value || [],
    itemData: $storage.getJSON('m-standorte:item_data').value || [],
    unsubscribe: [],
    swiperOptions: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      centerInsufficientSlides: true,
      spaceBetween: 0,
      touchRatio: 1,
      autoplay: {
        delay: 3000
      }
    },
    showAnsprechpartner: null
  }),
  computed: {
    items() {
      const scrape = this.scrapeData
      scrape.sort((a, b) => {
        return a.sort_no - b.sort_no
      })
      return scrape.map((item) => {
        const itemExtra = this.itemData.find(x => x.id === item.id || x.uri === item.id)
        if (itemExtra) {
          item = { ...item, ...itemExtra }
        }
        return item
      })
    }
  },
  mounted() {
    this.unsubscribe.push(db.collection('dresen-standorte-renew').onSnapshot(this.updateItems))
    this.unsubscribe.push(db.collection('dresen-standorte').onSnapshot(this.updateItemData))
  },
  beforeDestroy() {
    if (this.unsubscribe.length) {
      this.unsubscribe.forEach(x => x())
    }
  },
  methods: {
    updateItems({ docs }) {
      this.scrapeData = docs.map(x => ({ id: x.id, ...x.data() }))
      $storage.setJSON('m-standorte:scrape_data', this.scrapeData)
      this.$emit('loaded')
    },
    updateItemData({ docs }) {
      this.itemData = docs.map(x => ({ id: x.id, ...x.data() }))
      $storage.setJSON('m-standorte:item_data', this.itemData)
    },
    openAnsprechpartner({ id, title }) {
      this.showAnsprechpartner = id
      this.$emit('title-change', `Unser Team in ${title}`)
      this.$emit('page-backable', true)
    },
    back() {
      this.showAnsprechpartner = null
      this.$emit('title-change', this.title)
      this.$emit('page-backable', false)
    }
  }
}
</script>
