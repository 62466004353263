<template>
  <v-card flat>
    <v-card-title v-if="locationTitle">
      Unser Team in {{ locationTitle }}
    </v-card-title>
    <v-container grid-list-xl>
      <v-row>
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="4"
          class="d-flex align-stretch"
        >
          <v-card width="100%">
            <v-img
              v-if="item.foto"
              :src="item.foto | mImage"
              :lazy-src="item.foto | mImage({ w: 10 })"
              aspect-ratio="1"
            >
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate />
                </v-row>
              </template>
            </v-img>
            <v-img
              v-else
              :src="placeholder"
              aspect-ratio="1"
              style="background-color: grey;"
            />
            <v-card-text class="flex-column align-start pa-2 body-2 black--text">
              <div class="font-weight-bold body-1">
                {{ item.name }}
              </div>
              <div class="body-1 pb-3">
                {{ item.position }}
              </div>
              <div v-for="(phone, p) in item.telefons" :key="p">
                <v-icon left>
                  mdi-phone
                </v-icon>
                {{ phone }}
              </div>
              <div v-if="item.mail">
                <v-icon left>
                  mdi-email
                </v-icon>
                {{ item.mail }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { db } from '../../../plugins/firebase'
import $storage from '../../../plugins/local_storage'
export default {
  name: 'MAnsprechpartner',
  props: {
    locationId: {
      type: String,
      default: () => ''
    },
    locationTitle: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    dialog: true,
    items: $storage.getJSON('m-ansprechpartner:items').value || [],
    placeholder: 'https://www.dresen.de/fileadmin/DRESEN_Template/dresen-img/Icons/ma-placeholder.png',
    unsubscribe: null
  }),
  watch: {
    locationId: {
      handler(newId) {
        if (this.unsubscribe) {
          this.unsubscribe()
        }
        if (newId) {
          this.unsubscribe = db.collection('dresen-standorte-renew').doc(newId).onSnapshot(this.updateItems)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  methods: {
    updateItems(doc) {
      this.items = doc.data().ansprechpartner || []
      $storage.setJSON('m-ansprechpartner:items', this.items)
      this.$emit('loaded')
    }
  }
}
</script>
