export default {
  setTTL(key, ttl) {
    window.localStorage.setItem(`expires:${key}`, (new Date()).getTime() + ttl)
  },
  getTTL(key) {
    const expiresAt = window.localStorage.getItem(`expires:${key}`)
    return (expiresAt !== null) ? expiresAt - (new Date()).getTime() : null
  },
  set(key, value, ttl, encodeJSON) {
    window.localStorage.setItem(key, encodeJSON ? window.JSON.stringify(value) : value)
    if (ttl) {
      this.setTTL(key, ttl)
    }
    // console.log(`${key} saved in local storage with ttl ${ttl}`)
  },
  setJSON(key, value, ttl) {
    this.set(key, value, ttl, true)
  },
  get(key, withTTL, decodeJSON) {
    let value = window.localStorage.getItem(key)
    if (decodeJSON) {
      value = value ? window.JSON.parse(value) : null
    }
    const ttl = withTTL ? this.getTTL(key) : false
    // if (value) {
    //   console.log(`${key} retrieved from storage, ttl: ${ttl}`)
    // }
    return { value, ttl }
  },
  getJSON(key, withTTL) {
    return this.get(key, withTTL, true)
  },
  async getOrSet(key, valueFn, setTTL, isJSON) {
    const { value, ttl } = this.get(key, true, isJSON)
    if (!value || !setTTL || ttl <= 0) {
      try {
        const calculatedValue = await valueFn()
        this.setJSON(key, calculatedValue, setTTL)
        // console.log(`calculated new value for ${key}`)
        return calculatedValue
      } catch (e) {
        // eslint-disable-next-line
        console.error(e)
      }
    }
    return value
  },
  async getOrSetJSON(key, valueFn, setTTL) {
    const value = await this.getOrSet(key, valueFn, setTTL, true)
    return value
  },
  removeItem(key) {
    window.localStorage.removeItem(key)
  },
  clear() {
    window.localStorage.clear()
  }
}
